* {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    margin: 0;
    padding: 0;
  }
  
  a {
    color: #4eafc9;
    font-size: 1.5rem;
  }
  
  p {
    color: #a6b2c8;
    font-size: 1.5rem;
  }
  
  .App {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
  }
  
  .container {
    width: fit-content;
    background-color: rgba(31, 34, 41, 0.3);
    padding: 20px 20px 50px 20px;
    border-radius: 25px;
  }
  
  .container p{
    font-size: 1.2rem;
    width: 50ch;
    color: #fff;
    margin-bottom: 25px;
  }
  
  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  input {
    width: 70%;
    height: 50px;
    border-radius: 10px;
    padding: 2px 10px;
    outline: none;
    border: none;
    font-weight: 600;
    font-size: 1rem;
  }
  button[type="submit"] {
    width: 25%;
    background: #4eafc9;
    border: none;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 10px;
  }
  
  .other {
    display: flex;
    justify-content: space-between;
  }
  
  .bots {
    text-align: left;
  }
  
  .links img {
    width: 30px;
  }

  .alert {
    position: relative;
    padding: 0.4rem;
    margin: 0.5rem;
    color: white;
    text-align: center;
    font-size: 1.2rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    background: rgba(0, 255, 0, 0.1);
    backdrop-filter: blur(10px);
    z-index: 3;
  }